export var setToken = (tokenData) => {
	tokenData && localStorage.setItem('token', tokenData);
	return true;
};

export var getToken = () => {
	return localStorage.getItem('token');
};

export const logout = () => {
	localStorage.clear();
	window.location.reload();
};