import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
} from "reactstrap";
import { logout } from "../services/dataService";

const Sidebar = (props) => {
    const sidebarLogout = (event, onCLicked) => {
        if (onCLicked) {
            event.preventDefault();
            logout();
        }
    };
    // creates the links that appear in the left menu / Sidebar
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <NavItem key={key}>
                        <NavLink
                            to={prop.layout + prop.path}
                            tag={NavLinkRRD}
                            activeClassName="active"
                            onClick={(e) => sidebarLogout(e, prop.onclick)}
                        >
                            <i className={prop.icon} />
                            <span className="nav-link-text">{prop.name}</span>
                        </NavLink>
                    </NavItem>
                );
            } else {
                return 0;
            }
        });
    };

    const { routes, logo } = props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: "_blank",
        };
    }

    const [hovered, setHovered] = useState(false);
    const toggleHover = () => {
        setHovered(!hovered);
        if (document.body.classList.contains("g-sidenav-hidden")) {
            if (hovered) {
                document.body.classList.add("g-sidenav-hide");
                document.body.classList.remove("g-sidenav-show");
            } else {
                document.body.classList.add("g-sidenav-show");
                document.body.classList.remove("g-sidenav-hide");
            }
        }
    };

    return (
        <Navbar
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
            expand="md"
            id="sidenav-main"
        >
            <div className="scroll-wrapper scrollbar-inner">
                <div className="h-100 scrollbar-inner scroll-content scroll-scrolly_visible">
                    {/* Brand */}
                    {logo ? (
                        <div className="sidenav-header d-flex align-items-center">
                            <NavbarBrand {...navbarBrandProps}>
                                <img
                                    alt={logo.imgAlt}
                                    className="navbar-brand-img"
                                    src={logo.imgSrc}
                                />
                            </NavbarBrand>
                            <div className="ml-auto">
                                <div
                                    className={`sidenav-toggler d-none d-xl-block ${
                                        props.sidenavOpen ? "active" : ""
                                    }`}
                                    onClick={props.toggleSidenav}
                                >
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {/* User */}

                    {/* Collapse */}
                    <div className="navbar-inner">
                        {/* Navigation */}
                        <Collapse navbar id="sidenav-collapse-main">
                            <Nav navbar>{createLinks(routes)}</Nav>
                        </Collapse>
                    </div>
                </div>
            </div>
        </Navbar>
    );
};

Sidebar.defaultProps = {
    routes: [{}],
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default Sidebar;
