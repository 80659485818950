var service = {};

if (window.location.href.indexOf("localhost") > -1) {
    service.API_URL = "http://localhost:5000";
    service.FORWARDEN = "http://127.0.0.1:5050";
    service.DISCORDBOT = "http://localhost:5001";
} else {
    service.API_URL = "https://node.eazyloanamerica.com";
    service.FORWARDEN = "https://forwarden.thriftyspends.com";
    service.DISCORDBOT = "https://forwarden2.thriftyspends.com";
}

export default service;
