import constant from "./constants.js";
import { getToken, logout } from "./dataService.js";

export var createDiscordAPI = (data) => {
    showSpinner();
    return fetch(constant.API_URL + "/api/discord", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAllDiscordAPI = () => {
    showSpinner();
    return fetch(constant.API_URL + "/api/discord", {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateDiscordAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + "/api/discord/" + id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var updateDiscordStatusAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + "/api/discord/status/" + id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var deleteDiscordAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + "/api/discord/" + id, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getDiscordByIdAPI = (id, data) => {
    // showSpinner();
    return fetch(constant.API_URL + "/api/discord/" + id, {
        method: "GET",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                // hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var startForwardenDiscord = () => {
    showSpinner();
    return fetch(constant.DISCORDBOT + "/forwarden/start", {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var stopForwardenDiscord = () => {
    showSpinner();
    return fetch(constant.DISCORDBOT + "/forwarden/stop", {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

const showSpinner = () => {
    document.getElementById("main-content").style.display = "none";
};

const hideSpinner = () => {
    document.getElementById("main-content").style.display = "block";
};
