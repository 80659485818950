import constant from "./constants.js";
import { getToken, logout } from "./dataService.js";

export var loginAPI = (data) => {
    return fetch(constant.API_URL + "/api/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "content-Type": "application/json" },
    })
        .then((res) => res.json())
        .then(
            (result) => result,
            (err) => err
        );
};

export var getDashboardDataApi = (data) => {
    return fetch(constant.API_URL + "/api/admin/dashboard-data", {
        method: "POST",
        headers: {
            "content-Type": "application/json",
            body: JSON.stringify(data),
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                console.log(res);
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

// export var updateMultipleUserStatusAPI = (data) => {
// 	showSpinner();
// 	return fetch(constant.API_URL + '/api/users/multiple/status/', {
// 		method: 'PUT',
// 		body: JSON.stringify(data),
// 		headers: {
// 			'content-Type': 'application/json',
// 			Authorization: getToken(),
// 		},
// 	})
// 		.then((res) => {
// 			if (res && res.status === 401) {
// 				logout();
// 			} else {
// 				hideSpinner();
// 				return res.json();
// 			}
// 		})
// 		.then(
// 			(result) => result,
// 			(err) => err
// 		);
// };

export var updateAdminAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + "/api/admin/" + id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

export var getAdminByIdAPI = (id, data) => {
    showSpinner();
    return fetch(constant.API_URL + "/api/admin/" + id, {
        method: "GET",
        body: JSON.stringify(data),
        headers: {
            "content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => {
            if (res && res.status === 401) {
                logout();
            } else {
                hideSpinner();
                return res.json();
            }
        })
        .then(
            (result) => result,
            (err) => err
        );
};

const showSpinner = () => {
    document.getElementById("main-content").style.display = "none";
};

const hideSpinner = () => {
    document.getElementById("main-content").style.display = "block";
};
