import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

import Tables from "../../components/Tables";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Badge } from "reactstrap";
import {
    getAllDiscordAPI,
    deleteDiscordAPI,
    updateDiscordStatusAPI,
} from "../../services/api3";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

const DiscordList = () => {
    const history = useHistory();
    const [discordData, setDiscordData] = useState([]);
    const data = discordData;
    const [show, setShow] = useState(false);
    const [statusAlert, setStatusAlert] = useState(false);
    const [id, setID] = useState(null);
    const [discordStatus, setDiscordStatus] = useState(null);

    const columns = [
        { dataField: "id", text: "id", sort: true, hidden: true },
        { dataField: "fromName", text: "From Name", sort: true },
        { dataField: "fromId", text: "From ID", sort: true },
        { dataField: "toName", text: "To Name", sort: true },
        { dataField: "toId", text: "To ID", sort: true },
        { dataField: "garbageValue", text: "Garbage Value", sort: true },
        {
            dataField: "createdAt",
            text: "Created On",
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.createdAt
                        ? moment.utc(row.createdAt).format("DD-MM-YYYY")
                        : "N/A"}
                </>
            ),
        },
        {
            dataField: "discordStatus",
            text: "Status",
            sort: true,
            formatter: (cell, row) => (
                <>
                    {row.channelStatus === "1" ? (
                        <Badge
                            className="bg-success text-white badge-default"
                            onClick={(e) => e.preventDefault()}
                        >
                            Active
                        </Badge>
                    ) : (
                        <Badge
                            className="bg-danger text-white badge-default"
                            onClick={(e) => e.preventDefault()}
                        >
                            Inactive
                        </Badge>
                    )}
                </>
            ),
        },
        {
            dataField: "actions",
            text: "Action",
            isDummyField: true,
            formatter: (cell, row) => (
                <>
                    <Link
                        to={`/admin/discord/edit/${row.id}`}
                        className="table-action table-action-edit"
                        data-toggle="tooltip"
                        data-original-title="Edit product"
                    >
                        <i className="fas fa-user-edit"></i>
                    </Link>
                    <a
                        href="#!"
                        className="table-action table-action-delete"
                        data-toggle="tooltip"
                        data-original-title="Delete product"
                        onClick={(e) => deleteRow(e, row.id)}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                    <a
                        href="#!"
                        className="table-action table-action-toggle"
                        data-toggle="tooltip"
                        data-original-title="Update Status"
                        onClick={(e) => {
                            updateDiscordStatus(e, row.id, row.channelStatus);
                        }}
                    >
                        <i
                            className={
                                row.channelStatus === "1"
                                    ? "fas fa-toggle-on"
                                    : "fas fa-toggle-off"
                            }
                        ></i>
                    </a>
                </>
            ),
        },
    ];

    const deleteRow = (e, id) => {
        setID(id);
        e.preventDefault();
        setShow(true);
        SweetAlertFun();
    };

    const updateDiscordStatus = (e, id, discordStatus) => {
        setID(id);
        setDiscordStatus(discordStatus);
        e.preventDefault();
        setStatusAlert(true);
        SweetAlertFunToggle();
    };

    const SweetAlertFun = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={show}
                    onCancel={() => setShow(false)}
                    onConfirm={() => deleteDiscord()}
                ></SweetAlert>
            </>
        );
    };

    const SweetAlertFunToggle = () => {
        return (
            <>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, update status!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    show={statusAlert}
                    onCancel={() => setStatusAlert(false)}
                    onConfirm={() => updateStatus()}
                ></SweetAlert>
            </>
        );
    };

    const deleteDiscord = () => {
        deleteDiscordAPI(id).then((result) => {
            if (result) {
                setShow(false);
                toast.success("Record deleted sucessfully.");
                setTimeout(() => {
                    history.push("/admin/discord");
                    getAllDiscordAPI().then((discord) => {
                        setDiscordData(discord);
                    });
                }, 2000);
            } else {
                toast.error("Something went wrong.");
            }
        });
    };

    const updateStatus = () => {
        const status = { discordStatus };
        updateDiscordStatusAPI(id, status).then((result) => {
            if (result) {
                setStatusAlert(false);
                toast.success("Status updated successfully.");
                getAllDiscord();
            } else {
                toast.error("Something went wrong.");
            }
        });
    };

    useEffect(() => {
        getAllDiscord();
    }, []);

    const getAllDiscord = () => {
        getAllDiscordAPI().then((discord) => {
            setDiscordData(discord);
        });
    };

    return (
        <>
            <Breadcrumbs breadcrumbName="Discord Management" />
            <SweetAlertFun />
            <SweetAlertFunToggle />
            <Tables
                data={data}
                columns={columns}
                defaultSortKey="createdAt"
                defaultSortDirection="desc"
                currentPath="discord/create"
                tableTitle="Discord List"
                createBtn="true"
                discordBot={true}
            />
        </>
    );
};

export default DiscordList;
