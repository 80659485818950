import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

const Breadcrumbs = ({ breadcrumbName }) => {
	const history = useHistory();

	const goBack = (e) => {
		e.preventDefault();
		history.goBack();
	};
	return (
		<>
			<div
				className={`header bg-custom pt-5 pt-md-3 
					${breadcrumbName === 'Dashboard' ? 'pb-3' : 'pb-7'}`}>
				<Container fluid>
					<div className='header-body'>
						{/* Breadcrumbs */}
						<div className='row align-items-center py-4'>
							<div className='col-lg-10 col-sm-12'>
								<h6 className='h2 text-white d-inline-block mb-0'>
									{breadcrumbName}
								</h6>
								<nav
									aria-label='breadcrumb'
									className='d-none d-md-inline-block ml-md-4'>
									<ol className='breadcrumb breadcrumb-links breadcrumb-dark'>
										<li className='breadcrumb-item'>
											<Link to='/dashboard'>
												<i className='fas fa-home'></i>
											</Link>
										</li>
										<li className='breadcrumb-item'>
											<a href='#!' onClick={(e) => e.preventDefault()}>
												{breadcrumbName}
											</a>
										</li>
									</ol>
								</nav>
							</div>
							<div className='col-lg-2 col-sm-12'>
								<button
									onClick={(e) => goBack(e)}
									className='btn text-black btn-white'>
									Go Back
								</button>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Breadcrumbs;
