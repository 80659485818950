import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getDashboardDataApi } from "services/api";

import Breadcrumbs from "../components/Breadcrumbs";
const Dashboard = (props) => {
    const [dashboardData, setDashboardData] = useState(null);

    useEffect(() => {
        getDashboardDataApi().then((result) => {
            if (result) {
                console.log(result);
                setDashboardData(result);
            }
        });
    }, []);

    return (
        <>
            <Breadcrumbs breadcrumbName="Dashboard" />
            <div className="header bg-custom pb-7">
                <Container fluid>
                    <div className="header-body">
                        <Row style={{ marginBottom: "20px" }}>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    From Telegram Chats
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <NumberFormat
                                                        value={
                                                            dashboardData?.totalTelegramId
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                                                    <i className="fas fa-user" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    To Discord Channels
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <NumberFormat
                                                        value={
                                                            dashboardData?.totalDiscordId
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                                    <i className="fas fa-briefcase" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total Telegram Bot Running
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <NumberFormat
                                                        value={
                                                            dashboardData?.totalProcess
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                                    <i className="fas fa-dollar-sign" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ height: "150px" }}>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    From Discord Channels
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <NumberFormat
                                                        value={
                                                            dashboardData?.totalFromId
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                                                    <i className="fas fa-user" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    To Discord Channels
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <NumberFormat
                                                        value={
                                                            dashboardData?.totalToId
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                                    <i className="fas fa-briefcase" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" xl="4">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total Discord Bot Running
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    <NumberFormat
                                                        value={
                                                            dashboardData?.totalDiscordProcess
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                                    <i className="fas fa-dollar-sign" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Dashboard;
