import constant from "./constants.js";
import { getToken, logout } from "./dataService.js";

export var createChannelAPI = (data) => {
  showSpinner();
  return fetch(constant.API_URL + "/api/channel", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};

export var getAllChannelAPI = () => {
  showSpinner();
  return fetch(constant.API_URL + "/api/channel", {
    method: "GET",
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};

export var updateChannelAPI = (id, data) => {
  showSpinner();
  return fetch(constant.API_URL + "/api/channel/" + id, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};

export var updateChannelStatusAPI = (id, data) => {
	showSpinner();
	return fetch(constant.API_URL + '/api/channel/status/' + id, {
		method: 'PUT',
		body: JSON.stringify(data),
		headers: {
			'content-Type': 'application/json',
			Authorization: getToken(),
		},
	})
		.then((res) => {
			if (res && res.status === 401) {
				logout();
			} else {
				hideSpinner();
				return res.json();
			}
		})
		.then(
			(result) => result,
			(err) => err
		);
};

export var deleteChannelAPI = (id, data) => {
  showSpinner();
  return fetch(constant.API_URL + "/api/channel/" + id, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};

export var getChannelByIdAPI = (id, data) => {
  // showSpinner();
  return fetch(constant.API_URL + "/api/channel/" + id, {
    method: "GET",
    body: JSON.stringify(data),
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        // hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};

export var startForwarden = () =>{
  showSpinner();
  return fetch(constant.FORWARDEN + "/forwarden/start", {
    method: "GET",
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};

export var stopForwarden = () =>{
  showSpinner();
  return fetch(constant.FORWARDEN + "/forwarden/stop", {
    method: "GET",
    headers: {
      "content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => {
      if (res && res.status === 401) {
        logout();
      } else {
        hideSpinner();
        return res.json();
      }
    })
    .then(
      (result) => result,
      (err) => err
    );
};


const showSpinner = () => {
	document.getElementById('main-content').style.display = 'none';
};

const hideSpinner = () => {
	document.getElementById('main-content').style.display = 'block';
};
