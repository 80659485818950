import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Container, Col, Row, Card, CardHeader, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { startForwarden, stopForwarden } from "../services/api2";
import { startForwardenDiscord, stopForwardenDiscord } from "../services/api3";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// const defaultArray = [
//     {
//         value: null,
//         label: "Select",
//     },
//     {
//         value: "delete",
//         label: "Delete",
//     },
//     {
//         value: "active",
//         label: "Active",
//     },
//     {
//         value: "inactive",
//         label: "Inactive",
//     },
// ];

const Tables = ({
    data,
    columns,
    tableTitle,
    createBtn = false,
    currentPath = "dashboard",
    defaultSortKey,
    defaultSortDirection,
    discordBot,
}) => {
    let tableRef = React.createRef();
    const [show, setShow] = useState(false);
    // const [confirmBtnTitle, setConfirmBtnTitle] = useState("");
    // const [mainTitle, setMainTitle] = useState("");
    // const [showCancel, SetShowCancel] = useState(true);
    const history = useHistory();

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
    });

    const startBot = () => {
        // console.log("startBot - Telegram");
        startForwarden().then((result) => {
            if (result.result) {
                console.log(result);
                toast.success("Bot started successfully.");
                history.push("/admin/channel");
            } else {
                toast.error("Something went wrong.");
            }
        });
    };

    const stopBot = () => {
        // console.log("stopBot - Telegram");
        stopForwarden().then((result) => {
            if (result.result) {
                console.log(result);
                toast.success("Bot stopped successfully.");
                history.push("/admin/channel");
            } else {
                toast.error("Something went wrong.");
            }
        });
    };

    const startDiscordBot = () => {
        // console.log("startBot - Discord");
        startForwardenDiscord().then((result) => {
            if (result.result) {
                console.log(result);
                toast.success("Discord Bot started successfully.");
                history.push("/admin/discord");
            } else {
                console.log(result.message);
                toast.error(result.message);
            }
        });
    };

    const stopDiscordBot = () => {
        // console.log("stopBot - Discord");
        stopForwardenDiscord().then((result) => {
            if (result.result) {
                console.log(result);
                toast.success("Discord Bot stopped successfully.");
                history.push("/admin/discord");
            } else {
                console.log(result.message);
                toast.error(result.message);
            }
        });
    };

    const indication = () => {
        return (
            <div className="text-muted">
                <p>No Records Found!</p>
            </div>
        );
    };

    const defaultSorted = [
        {
            dataField: defaultSortKey,
            order: defaultSortDirection,
        },
    ];

    const SweetAlertFun = () => {
        return (
            <>
                <SweetAlert
                    warning
                    // showCancel={showCancel}
                    // confirmBtnText={confirmBtnTitle}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    // title={mainTitle}
                    show={show}
                    onCancel={() => setShow(false)}
                ></SweetAlert>
            </>
        );
    };

    return (
        <>
            {/* Table */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <Container className="mt--6" fluid>
                <Row>
                    <SweetAlertFun />
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="6">
                                        <h3 className="mb-0">{tableTitle}</h3>
                                    </Col>
                                    {createBtn && (
                                        <Col md="6" className="text-right">
                                            <Link to={currentPath}>
                                                <Button className="text-white btn-custom-green">
                                                    Create Record
                                                </Button>
                                            </Link>
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>
                            <ToolkitProvider
                                bootstrap4
                                keyField="id"
                                data={data}
                                columns={columns}
                                search
                            >
                                {(props) => (
                                    <div className="py-4">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1 w-100"
                                        >
                                            <Row>
                                                <Col md="9">
                                                    <Row>
                                                        <Col md="4">
                                                            <Button
                                                                type="button"
                                                                className="text-white btn-sm btn-success mr-3"
                                                                // onClick={
                                                                //     startBot
                                                                // }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    discordBot ===
                                                                    true
                                                                        ? startDiscordBot()
                                                                        : startBot();
                                                                    // if (
                                                                    //     discordBot ==
                                                                    //     true
                                                                    // )
                                                                    //     startDiscordBot();
                                                                    // else
                                                                    //     startBot();
                                                                }}
                                                            >
                                                                START BOT
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                className="text-white btn-sm btn-warning"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    discordBot ===
                                                                    true
                                                                        ? stopDiscordBot()
                                                                        : stopBot();
                                                                }}
                                                            >
                                                                STOP BOT
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        <BootstrapTable
                                            ref={tableRef}
                                            noDataIndication={indication}
                                            bootstrap4={true}
                                            defaultSorted={defaultSorted}
                                            bordered={false}
                                            pagination={pagination}
                                            {...props.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Tables;
