import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import jwtDecode from "jwt-decode";

// reactstrap components
import { Card, CardHeader, CardBody, FormGroup, Container, Row, Col, Button } from "reactstrap";

import Breadcrumbs from "../components/Breadcrumbs";
import { toast } from "react-toastify";
import { getAdminByIdAPI, updateAdminAPI } from "../services/api";
import { getToken } from "services/dataService";
import { logout } from "services/dataService";

const Profile = (props) => {
    const [adminDetails, setAdminDetails] = useState({});

    const formFields = {
        firstName: adminDetails ? adminDetails.firstName : "",
        lastName: adminDetails ? adminDetails.lastName : "",
        oldPassword: "",
        oldPasswordHash: adminDetails ? adminDetails.password : "",
        newPassword: "",
        confirmPassword: "",
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        oldPassword: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        newPassword: Yup.string(),
        confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    var updateUser = (data) => {
        console.log(data);
        updateAdminAPI(adminDetails.id, data).then((result) => {
            if (result && result.success) {
                if (result.code === "password_changed") {
                    toast.success(result.message);
                    logout();
                } else {
                    toast.success("Record updated successfully.");
                    getAdmin();
                }
            } else {
                toast.error(result.message);
            }
        });
    };

    const getAdmin = () => {
        if (getToken()) {
            const tokenData = jwtDecode(getToken());
            getAdminByIdAPI(tokenData.admin.id).then((data) => {
                setAdminDetails(data);
            });
        }
    };

    useEffect(() => {
        getAdmin();
    }, []);

    return (
        <>
            <Breadcrumbs breadcrumbName="Profile" />
            {/* Page content */}
            <Formik
                onSubmit={(values, actions) => {
                    updateUser(values);
                }}
                enableReinitialize
                initialValues={formFields}
                validationSchema={validationSchema}
                render={({ handleSubmit, errors, touched }) => (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">My account</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={handleSubmit}>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-first-name">
                                                                First name
                                                            </label>
                                                            <Field
                                                                name="firstName"
                                                                type="text"
                                                                id="input-first-name"
                                                                placeholder="First name"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.firstName && touched.firstName ? " error-box is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage name="firstName" component="div" className="is-invalid invalid-feedback" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-last-name">
                                                                Last name
                                                            </label>
                                                            <Field
                                                                id="input-last-name"
                                                                placeholder="Last name"
                                                                name="lastName"
                                                                type="text"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.lastName && touched.lastName ? " error-box is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage name="lastName" component="div" className="is-invalid invalid-feedback" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            {/* Change password */}
                                            <h6 className="heading-small text-muted mb-4">Change Password</h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-first-name">
                                                                Old Password
                                                            </label>
                                                            <Field
                                                                className="form-control-alternative form-control"
                                                                name="oldPassword"
                                                                placeholder="Old Password"
                                                                type="password"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-last-name">
                                                                New Password
                                                            </label>
                                                            <Field
                                                                id="input-new-password-name"
                                                                placeholder="New password"
                                                                name="newPassword"
                                                                type="password"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.newPassword && touched.newPassword ? " error-box is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="newPassword"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-last-name">
                                                                Confirm Password
                                                            </label>
                                                            <Field
                                                                id="input-confirm-password-name"
                                                                placeholder="Confirm password"
                                                                name="confirmPassword"
                                                                type="password"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.confirmPassword && touched.confirmPassword ? " error-box is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="confirmPassword"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col md="12" className="text-right">
                                                        <Link to={"/admin/dashboard"}>
                                                            <Button className="mr-3 text-black">Cancel</Button>
                                                        </Link>
                                                        <Button type="submit" className="text-white btn-info">
                                                            Submit
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            />
        </>
    );
};

export default Profile;
