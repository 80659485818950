import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile.js";
import Login from "./views/Login.js";
import ChannelList from "./views/Channel/ChannelList";
import ChannelView from "./views/Channel/ChannelView";
import DiscordList from "./views/DiscordConnection/DiscordList";
import DiscordView from "./views/DiscordConnection/DiscordView";
var routes = [
    /* dashboard */
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fas fa-tv text-green",
        component: Dashboard,
        layout: "/admin",
    },
    /* channel */
    {
        path: "/channel/create",
        component: ChannelView,
        layout: "/admin",
    },
    {
        path: "/channel/edit/:id",
        component: ChannelView,
        layout: "/admin",
    },
    {
        path: "/channel",
        name: "Channel Management",
        icon: "fas fa-address-card text-primary",
        component: ChannelList,
        layout: "/admin",
    },

    /* discord */
    {
        path: "/discord/create",
        component: DiscordView,
        layout: "/admin",
    },
    {
        path: "/discord/edit/:id",
        component: DiscordView,
        layout: "/admin",
    },
    {
        path: "/discord",
        name: "Discord Management",
        icon: "fas fa-address-card text-primary",
        component: DiscordList,
        layout: "/admin",
    },

    /* User profile */
    {
        path: "/user-profile",
        name: "Profile",
        icon: "fas fa-user text-yellow",
        component: Profile,
        layout: "/admin",
    },
    /* logout */
    {
        path: "/logout",
        name: "Logout",
        icon: "fas fa-sign-out-alt text-red",
        layout: "/admin",
        onclick: true,
    },
    /* login */
    {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth",
    },
];
export default routes;
