import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Container,
    Row,
    Col,
    Button,
} from "reactstrap";

import Breadcrumbs from "../../components/Breadcrumbs";
import { toast } from "react-toastify";
import {
    createChannelAPI,
    updateChannelAPI,
    getChannelByIdAPI,
} from "../../services/api2";
import { useHistory } from "react-router-dom";

const ChannelView = (props) => {
    const titleName = useState(
        props.match.path.endsWith("create")
            ? "Create Connection"
            : "Update Connection"
    );
    const buttonName = useState(
        props.match.path.endsWith("create") ? "Submit" : "Update"
    );
    const [channelDetail, setChannelDetails] = useState({});
    const history = useHistory();
    const formFields = {
        tcName: "",
        tcId: "",
        dcName: "",
        dcId: "",
        garbageValue: "",
    };
    const validationSchema = Yup.object().shape({
        tcName: Yup.string().required("Telegram Name is required"),
        tcId: Yup.string().required("Telegram ID is required"),
        dcName: Yup.string().required("Discord Name date is required"),
        dcId: Yup.string().required("Discord ID is required"),
        garbageValue: Yup.string().required("Garbage Value is required"),
    });

    var addChannel = (data) => {
        createChannelAPI(data).then((result) => {
            if (result.result) {
                toast.success("Channel added successfully.");
                history.push("/admin/channel");
            } else {
                toast.error("Something went wrong.");
            }
        });
    };

    var updateChannel = (data) => {
        updateChannelAPI(props.match.params.id, data).then((result) => {
            if (result) {
                toast.success("Channel updated successfully.");
                history.push("/admin/channel");
            } else {
                toast.error("Something went wrong.");
            }
        });
    };

    useEffect(() => {
        if (!props.match.path.endsWith("create")) {
            getChannelByIdAPI(props.match.params.id).then((user) => {
                setChannelDetails(user);
                console.log(user);
            });
        }
    }, []);

    return (
        <>
            <Breadcrumbs breadcrumbName="Channel Management" />
            {/* Page content */}
            <Formik
                onSubmit={(values, actions) => {
                    if (props.match.path.endsWith("create")) {
                        addChannel(values);
                    } else {
                        updateChannel(values);
                    }
                }}
                initialValues={
                    !props.match.path.endsWith("create")
                        ? channelDetail
                        : formFields
                }
                enableReinitialize
                validationSchema={
                    !props.match.path.endsWith("create") ? "" : validationSchema
                }
                render={({ handleSubmit, errors, touched }) => (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">
                                                    {titleName}
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <form onSubmit={handleSubmit}>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-id"
                                                            >
                                                                Telegram Name
                                                            </label>
                                                            <Field
                                                                id="input-id"
                                                                placeholder="Telegram Name"
                                                                name="tcName"
                                                                type="text"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.tcName &&
                                                                    touched.tcName
                                                                        ? " error-box is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="tcName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-id"
                                                            >
                                                                Telegram ID
                                                            </label>
                                                            <Field
                                                                id="input-id"
                                                                placeholder="Telegram ID"
                                                                name="tcId"
                                                                type="text"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.tcId &&
                                                                    touched.tcId
                                                                        ? " error-box is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="tcId"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-password"
                                                            >
                                                                Discord Name
                                                            </label>
                                                            <Field
                                                                id="input-password"
                                                                placeholder="Discord Name"
                                                                type="text"
                                                                name="dcName"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.dcName &&
                                                                    touched.dcName
                                                                        ? " error-box is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                hidden={
                                                                    !props.match.path.endsWith(
                                                                        "create"
                                                                    )
                                                                }
                                                                name="dcName"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-id"
                                                            >
                                                                Discord ID
                                                            </label>
                                                            <Field
                                                                id="input-id"
                                                                placeholder="Discord ID"
                                                                name="dcId"
                                                                type="text"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.dcId &&
                                                                    touched.dcId
                                                                        ? " error-box is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="dcId"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-id"
                                                            >
                                                                Garbage Value
                                                            </label>
                                                            <Field
                                                                id="input-id"
                                                                placeholder="Garbage Value"
                                                                name="garbageValue"
                                                                type="text"
                                                                className={
                                                                    "form-control-alternative form-control" +
                                                                    (errors.garbageValue &&
                                                                    touched.garbageValue
                                                                        ? " error-box is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="garbageValue"
                                                                component="div"
                                                                className="is-invalid invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col
                                                        md="12"
                                                        className="text-right"
                                                    >
                                                        <Link
                                                            to={
                                                                "/admin/channel"
                                                            }
                                                        >
                                                            <Button className="mr-3 text-black">
                                                                Cancel
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            type="submit"
                                                            className="text-white btn-info"
                                                        >
                                                            {buttonName}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            />
        </>
    );
};

export default ChannelView;
