import React from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    Container,
    Media,
} from "reactstrap";
import { getToken, logout } from '../services/dataService';
import jwtDecode from 'jwt-decode';

const Header = (props) => {
    const hideSidebar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-show");
        document.body.classList.add("nav-open");
        document.body.classList.remove("g-sidenav-hidden");
        document.body.classList.remove("g-sidenav-hide");
    };
	const { admin } = jwtDecode(getToken());

    return (
        <>
            <Navbar className="navbar navbar-top navbar-expand navbar-dark bg-custom border-bottom" expand="md" id="navbar-main">
                <Container fluid>
                    <Collapse navbar id="navbarSupportedContent">
                        <Nav className="align-items-center" navbar>
                            <NavItem className="d-xl-none">
                                <NavLink>
                                    <div className="pr-3 sidenav-toggler sidenav-toggler-dark">
                                        <div
                                            className="sidenav-toggler-inner"
                                            onClick={(e) => {
                                                hideSidebar(e);
                                            }}
                                        >
                                            <i className="sidenav-toggler-line" />
                                            <i className="sidenav-toggler-line" />
                                            <i className="sidenav-toggler-line" />
                                        </div>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <Nav className="navbar-nav align-items-center ml-auto" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="pr-0" nav>
                                    <Media className="align-items-center">
                                        <span className="avatar avatar-sm rounded-circle">
                                            <img alt="..." src={require("../assets/img/brand/default-img.jpg").default} />
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">
                                                {admin.firstName + ' ' + admin.lastName}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow custom-dropdown-menu" right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Welcome!</h6>
                                    </DropdownItem>
                                    <DropdownItem to="/admin/user-profile" tag={Link}>
                                        <i className="fas fa-user" />
                                        <span>My profile</span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={logout}>
                                        <i className="fas fa-sign-out-alt" />
                                        <span>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
